@import "@styles/mixins";
@import "@styles/variables";

.tabNavigationContainer {
  position: sticky;
  z-index: 20;
  top: 0;
  background-color: var(--white);
  padding-top: 18px;

  @include mobile-all {
    padding-top: 0;
  }
}

.tabNavigation {
  color: var(--colorV2-black);
  font-size: var(--font-size-20);
  font-weight: 500;
  box-shadow: inset 0 -2px 0 0 var(--colorV2-grey-medium-dark);
  display: flex;
  gap: 40px;

  @include non-desktop {
    gap: 20px;
  }

  @include mobile-all {
    font-size: var(--font-size-16);
  }
}

.tabNavigationItem {
  color: var(--colorV2-black);
  padding: 0;

  &:hover {
    cursor: pointer;
    span {
      background-color: var(--colorV2-grey-light);
    }
  }
}
