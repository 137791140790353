@import "@styles/variables";
@import "@styles/mixins";

.cardAnchorWrapper {
  min-width: 0;
  @include card-anchor;
  @include non-desktop {
    width: 100%;
  }

  &:hover {
    .cardContainer {
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.cardContainer {
  background-color: var(--colorV2-grey-light);
  border: 1px solid var(--colorV2-grey-medium);
  border-radius: 16px;
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
}

.layoutSizer {
  container-type: inline-size;
}

.miniImageWrapper {
  width: 120px;
  height: 140px;
  flex-shrink: 0;
}

.imageWrapper {
  height: auto;
  object-fit: cover;
  position: relative;
  padding: 20px 0 20px 20px;
  min-width: 190px;

  @include mobile-all {
    padding: 20px 20px 0 20px;
    min-width: 0;
  }
}

.responseTimeTextGood {
  color: var(--colorV2-darkest-green);
}

.responseTimeTextBad {
  color: var(--colorV2-grey-dark);
}

.name {
  flex-wrap: nowrap;
  @include webkit-line-clamp(1);
}

.jobTitle {
  color: var(--colorV2-grey-dark);
  @include webkit-line-clamp(1);
}

.sessionCity {
  flex-wrap: nowrap;
  @include webkit-line-clamp(1);
}

.categories {
  @include webkit-line-clamp(2);
}

.profileTag {
  background-color: var(--colorV2-light-green);
  color: var(--colorV2-darkest-green);
  border-radius: 100px;
  white-space: nowrap;
  min-width: 0;
  max-width: 100%;
  width: fit-content;
  padding: 6px 12px;

  &.iconLayout {
    padding: 6px;
    border-radius: 50%;
    width: 27px;
    height: 27px;
  }

  &.beigeLayout {
    background-color: var(--colorV2-beige);
    color: var(--colorV2-black);
    gap: 6px;
    padding: 2px 8px;
  }
}

.sessionFormatsIcons {
  display: block;

  @include mobile-all {
    display: none;
  }
}

.sessionFormatsText {
  display: none;

  @include mobile-all {
    display: block;
  }
}

.favoriteButtonMobile {
  display: none;

  @include mobile-all {
    display: block;
    position: absolute;
    top: 36px;
    right: 36px;
  }
}

.favoriteButtonDesktop {
  display: block;

  @include mobile-all {
    display: none;
  }
}

.seperator {
  display: block;

  @include mobile-all {
    display: none;
  }
}
