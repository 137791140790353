@import "@styles/variables";
@import "@styles/mixins";

.accordionContainer {
  height: 100%;
}

.accordionItem {
  border-left: 3px solid var(--colorV2-medium-green);
  border-radius: 0 10px 10px 0;
  margin: 20px 0;

  &.isActive {
    border-left-color: var(--colorV2-dark-green);
    background-color: var(--colorV2-light-green);
    transition:
      background-color 400ms ease-in-out,
      border-left-color 400ms ease-in-out;

    &:hover {
      .accordionTrigger {
        cursor: unset;
      }
    }
  }

  &:hover {
    border-left-color: var(--colorV2-dark-green);
    transition: border-left-color 400ms ease-in-out;
  }
}

.accordionTrigger {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: none;
  padding: 20px;
  background: transparent;
  color: var(--colorV2-dark-green);

  &:hover {
    cursor: pointer;
  }
}

.accordionContent {
  padding: 0 20px 20px 20px;
  overflow: hidden;

  &[data-state="open"] {
    animation: slideDown 300ms ease-in-out;
  }

  &[data-state="closed"] {
    animation: slideUp 300ms ease-in-out;
  }
}

.accordionImage {
  display: none;
  opacity: 0;
  max-width: 350px;
  max-height: 350px;
  width: 100%;
  height: auto;
  object-fit: contain;

  &.isActive {
    display: block;
    opacity: 0;
    animation: fadeIn 300ms ease-in-out 80ms forwards;
  }

  @include mobile-all {
    max-width: 150px;
    max-height: 150px;
  }
}

.carouselContainer {
  margin: auto;
  position: relative;
  max-width: 745px;

  @include mobile-all {
    max-width: 592px;
  }
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.carouselPreviousButton {
  left: 8px;
}

.carouselNextButton {
  right: 8px;
}

.testimonialCarousel {
  &:hover {
    .carouselPreviousButton,
    .carouselNextButton {
      opacity: 1;
      visibility: visible;
    }
  }

  .carouselPreviousButton,
  .carouselNextButton {
    opacity: 0;
    visibility: hidden;

    @include mobile-all {
      display: none;
    }
  }
}

.testimonialCard {
  border-radius: 20px;
  transition: all 300ms ease;
  min-height: 653px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-color: transparent;
  justify-content: space-between;

  &::before {
    background-color: var(--white);
  }

  &::before,
  &::after {
    border-radius: 20px;
    box-shadow: none;
  }
}

.testimonialCardLogoContainer {
  border-bottom: 1px solid var(--colorV2-grey-medium);
}

.testimonialCardQuoteContainer {
  flex-grow: 1;
}

.testimonialAuthorImage {
  border-radius: 50%;
  filter: grayscale(1);
}

.therapistsStatsImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 465px;

  @include mobile-all {
    max-height: 290px;
  }
}

.therapistsStatsIconContainer {
  width: 80px;

  @include mobile-all {
    width: 50px;
  }
}

.therapistsStatsIcon {
  color: var(--colorV2-dark-green);

  @include mobile-all {
    width: 50px;
    height: 50px;
  }
}

.therapistsStatsTitle {
  color: var(--colorV2-dark-green);
  font-size: 95px;
  font-weight: 600;

  @include non-desktop {
    font-size: 80px;
  }

  @include mobile-all {
    font-size: 60px;
  }
}

.dashboardBenefitIconContainer {
  margin-top: 2px;
  width: 34px;
}

.dashboardBenefitIcon {
  color: var(--colorV2-dark-green);
}

.dashboardBenefitTitle {
  color: var(--colorV2-dark-green);
}

.dashboardImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.scrollingLogosContainer {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.scrollingLogos {
  display: inline-flex;
  animation: scroll 35s linear infinite;
  will-change: transform;
}

.logo {
  height: 60px;
  width: 120px;
  object-fit: contain;
  filter: grayscale(1);
  opacity: 0.5;
  margin: 0 20px;
}

.whyICStatsCard {
  border-radius: 20px;
  border-color: transparent;
  flex: 1 1 calc(25% - (var(--space-5)));

  @include non-desktop {
    flex: 1 1 calc(50% - (var(--space-5)));
  }

  @include mobile-all {
    flex: 1 1 calc(100% - (var(--space-5)));
  }

  &::before {
    background-color: var(--colorV2-beige-semi);
  }

  &::before,
  &::after {
    border-radius: 20px;
    box-shadow: none;
  }
}

.whyICStatsIconContainer {
  width: 80px;

  @include mobile-all {
    width: 50px;
  }
}

.whyICStatsIcon {
  color: var(--colorV2-dark-green);

  @include mobile-all {
    width: 50px;
    height: 50px;
  }
}

.whyICStatsText {
  color: var(--colorV2-dark-green);
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33.333%);
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
