@import "@styles/variables";

.waveBackgroundImage {
  width: 100%;
  height: 220px;
  background-size: cover;
}

.mainContainer {
  color: var(--colorV2-black);
}
