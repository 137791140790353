.mapContainer {
  background-size: cover; /* cover ensures the div is always covered */
  background-position: center; /* centers the background image */
}

.mapOverlay {
  color: var(--colorV2-grey-light);
  background-color: var(--colorV2-black);
  border-radius: 24px;
}

.textOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px; /* or whatever width you prefer */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.75); /* semi-transparent white */
  text-align: center;
}
