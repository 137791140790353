@import "@styles/mixins";
@import "@styles/variables";

.container {
  .clamp {
    @include webkit-line-clamp(5);

    @include mobile-all {
      @include webkit-line-clamp(4);
    }
  }

  &.profileLayout {
    .clamp {
      @include webkit-line-clamp(unset);

      @include mobile-all {
        @include webkit-line-clamp(10);
      }
    }

    .buttonContainer {
      display: none;

      @include mobile-all {
        display: flex;
      }
    }

    .button {
      color: var(--colorV2-black);
      font-weight: 500;
      text-decoration: underline;
      text-underline-offset: 2.4px;
      transition: color 300ms ease;

      &:hover {
        color: var(--colorV2-dark-green);
        cursor: pointer;
      }
    }
  }
}
