@import "@styles/variables";
@import "@styles/mixins";

.title {
  padding-bottom: 2px;
  border-bottom: 2px solid var(--colorV2-light-green);
  width: fit-content;
}

.button {
  padding-left: 16px !important;
  padding-right: 16px !important;

  @include mobile-all {
    max-width: 282px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.tooltip {
  @include mobile-all {
    display: none;
  }
}
