@import "@styles/variables";
@import "@styles/mixins";

// mobile-portrait = 1 column, variant 3
// mobile-landscape = 1 columns, variant 1
// tablet = 2 columns, variant 3
// sm-desktop = 2 columns, variant 2
// wide-desktop = 2 columns, variant 1

@mixin variant1 {
  @container (min-width: 626px) {
    @content;
  }
}

@mixin variant2 {
  @container (min-width: 438px) and (max-width: 625px) {
    @content;
  }
}

@mixin variant3 {
  @supports not (container-type: inline-size) {
    @content; // Apply as default only for browsers that do not support container queries
  }
  @container (max-width: 438px) {
    @content;
  }
}

@mixin variant1And2 {
  @include variant1 {
    @content;
  }
  @include variant2 {
    @content;
  }
}

@mixin variant2And3 {
  @include variant2 {
    @content;
  }
  @include variant3 {
    @content;
  }
}

.variant1 {
  display: none;

  @include variant1 {
    display: block;
  }
}

.variant2 {
  display: none;

  @include variant2 {
    display: block;
  }
}

.variant3 {
  display: none;

  @include variant3 {
    display: block;
  }
}

.variant1And2 {
  display: none;

  @include variant1And2 {
    display: block;
  }
}

.variant2And3 {
  display: none;

  @include variant2And3 {
    display: block;
  }
}

.cardAnchorWrapper {
  min-width: 0;
  @include card-anchor;
  @include non-desktop {
    width: 100%;
  }

  &:hover {
    .cardContainer {
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.cardContainer {
  padding: 18px;
  background: var(--colorV2-grey-light);
  border: 1px solid var(--colorV2-grey-medium);
  border-radius: 16px;
  transition: box-shadow 0.3s ease-in-out;

  @include mobile-all {
    padding: 12px;
    border-radius: 6px;
  }
}

.layoutSizer {
  container-type: inline-size;
}

.miniImageWrapper {
  width: 120px;
  height: 140px;
  flex-shrink: 0;
}

.imageWrapper {
  width: 160px;
  height: 190px;
  flex-shrink: 0;

  //mobile portrait and tablet
  @include variant3 {
    width: 120px;
    height: 140px;
  }
  @include variant2 {
    width: 160px;
    height: 190px;
  }

  @include variant1 {
    width: 220px;
    height: 260px;
  }
}

.cardTextColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  justify-content: flex-start;
  gap: 24px;

  @include variant3 {
    justify-content: center;
  }

  @include variant2 {
    justify-content: flex-start;
    gap: 12px;
  }
}

.responseTimeTextGood {
  color: var(--colorV2-darkest-green);
}

.responseTimeTextBad {
  color: var(--colorV2-grey-dark);
}

.name {
  flex-wrap: nowrap;
  @include webkit-line-clamp(1);
}

.jobTitle {
  color: var(--colorV2-grey-dark);
  @include webkit-line-clamp(1);
  @include variant3 {
    @include webkit-line-clamp(2);
  }
}

.categories {
  @include webkit-line-clamp(3);
}

.categoriesMini {
  @include webkit-line-clamp(2);
}

@mixin optionalMinWidth($useMinWidth: true) {
  background-color: var(--colorV2-light-green);
  color: var(--colorV2-darkest-green);
  border-radius: 100px;
  white-space: nowrap;
  @if $useMinWidth {
    min-width: 0;
  }
}

.footerTag {
  @include optionalMinWidth; // Use mixin with default min-width
  max-width: 100%;
}

.footerTagNoMinWidth {
  @include optionalMinWidth(false); // Use mixin without min-width
  max-width: 100%;
}

.footerTagFormatsText {
  @include truncate;
}
