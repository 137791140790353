@import "@styles/variables";
@import "@styles/mixins";

.steps {
  position: relative;
}

.stepsLine {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--colorV2-light-green);
  transform: translateX(-50%);

  @include mobile-all {
    left: 35px;
  }
}

.stepsContainer {
  position: relative;
}

.stepContainer {
  &:last-child {
    background-color: var(--white);
  }
}

.step {
  display: flex;
  position: relative;

  &.right {
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    margin-left: calc(50% - 35px);

    @include mobile-all {
      margin-left: 0;
    }

    .contentBox {
      margin-left: 32px;

      @include mobile-all {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  &.left {
    flex-direction: row-reverse;
    text-align: right;
    margin-right: calc(50% - 35px);

    @include mobile-all {
      flex-direction: row;
      text-align: left;
      margin-right: 0;
    }

    .contentBox {
      margin-right: 32px;

      @include mobile-all {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }
}

.icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorV2-light-green);
  border-radius: 50%;
  font-size: 1.5rem;
  transition:
    background-color 0.6s ease,
    color 0.6s ease;
  color: var(--colorV2-dark-green);

  &.active {
    background-color: var(--colorV2-dark-green);
    color: var(--white);
  }
}

.contentBox {
  background-color: var(--colorV2-light-green);
  border-radius: 12px;
  padding: 18px 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.6s ease,
    transform 0.6s ease;

  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  @include mobile-all {
    width: 100%;
  }
}

.contentBoxTitle {
  color: var(--colorV2-dark-green);
}
