@import "@styles/variables";
@import "@styles/mixins";

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-2);
}

.carousel {
  min-width: 0;
  width: 100%;
  position: relative;

  &:hover {
    .carouselPreviousButton,
    .carouselNextButton {
      opacity: 1;
      visibility: visible;
    }
  }
}

.carouselContent {
  gap: 0 !important;
}

.carouselItem {
  flex-basis: 100% !important;
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 300ms ease,
    visibility 300ms ease;
}

.carouselPreviousButton {
  left: 8px;
}

.carouselNextButton {
  right: 8px;
}

.carouselImage {
  border-radius: 28px !important;

  @include mobile-all {
    border-radius: 0 !important;
  }
}

.carouselPaginationDots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
