@import "@styles/variables";
@import "@styles/mixins";
@import "@styles/placeholders";

.waveHolder {
  width: 100%;
  height: 50px;
  max-height: 50px;
  background-size: auto 100%;
}

.footerHolder {
  background-color: var(--colorV2-light-green);
}

.disclaimerHolder {
  background-color: var(--colorV2-grey-light);

  a {
    @extend %darkGreenUnderlineLink;
  }
}

.logoWrapper {
  position: relative;
  height: 24px;
}

.sectionLinksContainer {
  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    @extend %darkGreenLink;
  }

  @include mobile-all {
    flex: 0 0 100%;
  }
}
