.carousel {
  min-width: 0;
  width: 100%;
  position: relative;
}

.carouselContent {
  gap: 0 !important;
}

.carouselItem {
  flex-basis: 100% !important;
  text-align: center;
}
