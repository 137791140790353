@import '@styles/variables';
@import '@styles/mixins';

.heroText {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  a:not([type='button']) {
    text-decoration: none;
    color: var(--colorV2-dark-green);

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

.heroImageOuterContainer {
  display: flex;
  justify-content: center;
  align-items: center; /* Align the image to the top */
  flex-basis: 35%;
  flex-shrink: 0;
}

.heroImageContainer {
  display: flex;
  align-items: flex-start; /* This will help keep the image aligned to the top */
  justify-content: center;
  height: auto;
  width: 100%;
}

.responsiveImage {
  width: 100%; /* Ensure the image can scale up to the width of its container */
  object-fit: contain; /* Maintains aspect ratio within the given dimensions */
  height: auto;
  max-height: 350px; /* Adjust based on your specific design needs */

  @include tablet {
    max-height: 200px; /* Adjust based on your specific mobile design needs */
  }

  @include mobile-all {
    max-height: 120px; /* Adjust based on your specific mobile design needs */
  }
}
