@import "@styles/variables";
@import "@styles/mixins";

.directoryCardsListContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: 100%;
  padding-top: 0;
  padding-bottom: 0;
  align-items: stretch;

  @container (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }
}

.newDirectoryCardsListContainer {
  display: grid;
  gap: 20px;

  padding-top: 0;
  padding-bottom: 0;
  align-items: stretch;
  grid-template-columns: 1fr 1fr;

  @include non-desktop {
    grid-template-columns: 100%;
  }
}
