@import "@styles/variables";

.text {
  p {
    margin-bottom: 24px;
  }

  b {
    font-weight: 500;
  }
}

.image {
  width: 100%;
  height: auto;
  outline: 2px solid var(--colorV2-pink);
  outline-offset: -2px;
  border-radius: 21px;
  max-height: 550px;
  object-fit: cover;
}
