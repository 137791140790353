@import "@styles/variables";
@import "@styles/mixins";

.container {
  color: var(--colorV2-black);
}

.messageRequired {
  color: var(--colorV2-dark-green);
}

.messageArea {
  display: block;
  padding: 12px;
  resize: none;
  min-height: 200px;
  border: 2px solid var(--grey-light);
  outline: none;

  @include mobile-all {
    height: calc(100% - 80px);
  }
}

.scrollableFormContent {
  justify-content: stretch;
  padding: var(--spacer-size-5);
  padding-bottom: var(--spacer-size-8);
  height: 100%;
}

.availabilityRadioGroup {
  display: grid;
  margin-bottom: var(--spacer-size-3);
  margin-left: var(--spacer-size-2);
  margin-right: var(--spacer-size-2);
}

.appointmentPickerDate {
  color: --colorV2-grey-dark;
}

.appointmentPickerDateRule {
  flex-grow: 1;
  border-top: 1px solid var(--colorV2-grey-medium);
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.item {
  border-radius: 6px;
  cursor: pointer;
  font-weight: 400;
  color: var(--colorV2-dark-green);
  background-color: var(--colorV2-grey-light);
  border: 2px solid var(--colorV2-grey-light);
  padding: 0 21px;
  height: 36px;
  width: 86px;
  -webkit-tap-highlight-color: transparent;
  transition:
    color,
    background-color 300ms ease;

  &:hover {
    font-weight: 500;
    color: var(--colorV2-black);
    background-color: var(--colorV2-light-green);
    border-color: var(--colorV2-grey-medium-dark);
  }

  &:focus {
    border-color: var(--colorV2-grey-dark);
  }

  &[data-state="checked"] {
    font-weight: 500;
    color: var(--colorV2-black);
    background-color: var(--colorV2-light-green);
    border-color: var(--colorV2-grey-dark);
  }

  &[data-state="unchecked"] {
    background-color: var(--grey-light);
  }
}

.appointmentIndicator {
  white-space: nowrap;
}

.button {
  @include mobile-all {
    width: 100%;
  }
}
