@import "@styles/variables";
@import "@styles/mixins";

.badge {
  color: var(--colorV2-darkest-green);
  font-size: var(--font-size-18);
  font-weight: 400;
  padding: 10px 16px;
  align-self: flex-start;
  gap: 12px;

  @include mobile-all {
    font-size: var(--font-size-16);
  }

  &:nth-child(odd) {
    background-color: var(--colorV2-grey-light-medium);
  }

  &:nth-child(even) {
    background-color: var(--colorV2-beige);
  }
}
