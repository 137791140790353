@import "@styles/mixins";
@import "@styles/variables";

.videoContainer {
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.playIconContainer {
  width: 70px;
  height: 70px;
  position: absolute;
  margin: auto;
  color: var(--colorV2-black);
  background-color: hsla(0, 0%, 100%, 0.61);
  box-shadow: 0px 4px 4px 0px var(--blackA8);
  border-radius: 50%;

  @include mobile-all {
    width: 50px;
    height: 50px;
  }
}

.playIcon {
  width: 48px;
  height: 48px;

  @include mobile-all {
    width: 32px;
    height: 32px;
  }
}

.videoSkeleton {
  aspect-ratio: calc(16 / 9);
  width: auto;
  height: auto;
}
