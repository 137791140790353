@import "@styles/variables";
@import "@styles/mixins";

.subtitle {
  width: 300px;
}

.imageWrapper {
  width: 160px;
  height: 190px;
  flex-shrink: 0;

  //mobile portrait and tablet
  @include mobile-portrait {
    width: 120px;
    height: 140px;
  }
  @include tablet {
    width: 120px;
    height: 140px;
  }

  @include desktop-wide {
    width: 220px;
    height: 260px;
  }
}

.scrollWrapper {
  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    z-index: 1;
    background: linear-gradient(to right, var(--colorV2-beige) 0%, transparent);

    @include mobile-portrait {
      width: 20px;
    }
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: scaleX(-1);
  }
}

.scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, WebKit-based browsers */
  }
}

.scrollButton {
  z-index: 5;
  svg {
    width: 24px;
    height: 24px;
  }
}

.overlayScrollButton {
  @extend .scrollButton;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: all;
  z-index: 2;
}

.overlayScrollButtonLeft {
  @extend .overlayScrollButton;
  left: 20px;
  @include mobile-portrait {
    display: none;
  }
}

.overlayScrollButtonRight {
  @extend .overlayScrollButton;
  right: 20px;
  @include mobile-portrait {
    display: none;
  }
}
