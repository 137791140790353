@import "@styles/mixins";

.container {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--white);
  height: auto;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: var(--spacer-size-1);
  z-index: 30;
  box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.1);
  color: var(--colorV2-black);

  @include mobile-all {
    display: flex;
  }
}
