@import "@styles/mixins";
@import "@styles/variables";

.accordionItem {
  overflow: hidden;
  border: 1px solid var(--color-transparent);
}

.accordionAnswer {
  padding: 12px 12px 4px 12px;
  border-radius: 12px;
  @include genericHtmlStyling;
  margin-left: -24px;

  @include tablet {
    margin-left: -12px;
  }

  @include mobile-all {
    margin-left: -4px;
  }
}

.accordionContent {
  overflow: hidden;
  padding-left: 24px;

  @include tablet {
    padding-left: 12px;
  }

  @include mobile-all {
    padding-left: 4px;
  }
}

.accordionContent[data-state="open"] {
  animation: slideDown 250ms ease-in-out;
}

.accordionContent[data-state="closed"] {
  animation: slideUp 250ms ease-in-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.chevronIcon {
  color: var(--colorV2-black);
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  width: 32px;
  height: 32px;
  stroke-width: 1px;
  flex-shrink: 0;
}

.faqRoot {
  border: none;
  width: 100%;
}

.faqCategoryTrigger {
  background-color: transparent;
  border: 1px solid var(--color-transparent);
  min-height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-align: start;
  gap: 12px;
  padding: 8px 4px 8px 12px;
  transition: background-color 300ms ease-in-out;
}

.faqCategoryTrigger:hover {
  background-color: var(--colorV2-beige-over);
}

.faqCategoryTrigger[data-state="open"] .chevronIcon {
  transform: rotate(45deg);
}

.underlineLayout {
  .accordionItemParent {
    border-bottom: 1px solid var(--colorV2-grey-medium-dark);
  }

  .faqCategoryTrigger:hover {
    background-color: var(--colorV2-grey-light);
  }
}

.whiteLayout {
  .faqCategoryTrigger:hover {
    background-color: var(--colorV2-grey-light);
  }
}
