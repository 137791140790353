@import "@styles/mixins";

.carouselContainer {
  width: 100%;
  position: relative;

  &:hover {
    .carouselPreviousButton,
    .carouselNextButton {
      opacity: 1;
      visibility: visible;
    }
  }
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  @include mobile-all {
    display: none;
  }
}

.carouselPreviousButton {
  left: 8px;
}

.carouselNextButton {
  right: 8px;
}
