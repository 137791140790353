@import '@styles/variables';
@import '@styles/mixins';

.cardAnchor {
  @include card-anchor;
}

.postContainer {
  flex: 1;
  background-color: var(--colorV2-beige);
  border-radius: 12px;
  max-width: 600px;
}

.postImageWrapper {
  border-radius: 20px; // Adjust as needed
  background-color: var(--colorV2-beige-outline-over);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 66.66%; /* 3:2 aspect ratio (100 / (3 / 2)) */
}
