@import "@styles/variables";

.container {
  border-radius: 20px;
  border: 1px solid var(--colorV2-grey-medium);
  background-color: var(--white);
  filter: brightness(1);
  transition: filter 0.3s ease;

  &:hover {
    cursor: pointer;
    filter: brightness(0.95);
  }
}

.imageContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-2);
}

.image {
  border-radius: 19px 19px 0 0 !important;
}
