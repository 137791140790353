@import "@styles/variables";
@import "@styles/mixins";

.arrowButton {
  cursor: pointer;
  color: var(--colorV2-black);
  background-color: var(--white);
  border-radius: 50%;
  border: none;
  box-shadow: 0px 4px 4px 0px var(--blackA8);
  transition: all 300ms ease;
  width: 30px;
  height: 30px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--colorV2-light-green);
  }

  &.isHidden {
    display: none;
    pointer-events: none;
  }

  &.isGreenLayout {
    color: var(--white);
    background-color: var(--colorV2-medium-green);
    box-shadow: none;

    &:hover {
      background-color: var(--colorV2-dark-green);
    }
  }

  &.isWhiteLayout {
    color: var(--white);
    background-color: transparent;
    box-shadow: none;
    width: 60px;
    height: 60px;

    &:hover {
      color: var(--colorV2-light-green);
    }

    .arrowIcon {
      width: 60px;
      height: 60px;
      filter: drop-shadow(0px 4px 2px var(--blackA8));
      stroke-width: 1.5;
    }
  }

  &.desktopOnly {
    @include mobile-all {
      display: none;
    }
  }
}
