@import "@styles/mixins";
@import "@styles/variables";

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.carouselContainer {
  width: 100%;
  position: relative;

  &:hover {
    .carouselPreviousButton:not(.isHidden),
    .carouselNextButton:not(.isHidden) {
      opacity: 1;
      visibility: visible;
    }
  }
}

.carouselPictures {
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  gap: 25px;

  @include mobile-all {
    gap: 20px;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, WebKit-based browsers */
  }
}

.carouselPicture {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-decoration: none;
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.carouselPreviousButton {
  left: 8px;
}

.carouselNextButton {
  right: 8px;
}

.pictureContainer {
  width: 182px;
  height: 182px;
  flex-shrink: 0;
  cursor: pointer;

  @include mobile-all {
    width: 134px;
    height: 134px;
  }
}

.picture {
  border-radius: 5px !important;

  &.isHidden {
    display: none;
  }
}

.mainPicture {
  border-radius: 8px !important;
}

.dialogPictureOverlay {
  background-color: var(--blackA9);
  position: fixed;
  z-index: 0;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.dialogPictureContainer {
  max-width: 80vw;
  max-height: 80vh;
  aspect-ratio: 1;
  position: fixed;
  margin: auto;
  border-radius: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include mobile-all {
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
  }
}

.dialogPictures {
  div {
    position: absolute !important;
    top: 0;
    left: 0;
  }
}

.dialogLeftArrowButton,
.dialogRightArrowButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.dialogLeftArrowButton {
  left: -70px;

  @include mobile-all {
    left: 0;
  }
}

.dialogRightArrowButton {
  right: -70px;

  @include mobile-all {
    right: 0;
  }
}

.dialogCloseButton {
  position: fixed;
  z-index: 1;
  top: 10px;
  right: 10px;
  color: var(--white);
  pointer-events: auto;
  transition: color 300ms ease;

  &:hover {
    cursor: pointer;
    color: var(--colorV2-light-green);
  }
}
