@import '@styles/mixins';
@import '@styles/variables';

.styledBoxSmall {
  border-radius: 12px;
  @include mobile-all {
    border-radius: 8px;
  }
}

.styledBoxMedium {
  border-radius: 24px;
  @include mobile-all {
    border-radius: 12px;
  }
}

.styledBoxLarge {
  border-radius: 40px;
  @include mobile-all {
    border-radius: 20px;
  }
}
