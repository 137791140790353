@import "@styles/variables";
@import "@styles/mixins";

.imageWrapper {
  background-color: var(--grey-low);
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  aspect-ratio: 1;
  width: 100%;
  height: 100%;

  .withGradient::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 33%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0) 95%
    );
    z-index: 1; // This ensures the gradient is above the image
  }

  .playButtonHolder {
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 2; // This ensures the button is above the gradient

    width: 32px;
    height: 32px;

    @include desktop-wide {
      width: 40px;
      height: 40px;
    }
  }
}

.newDirectoryCardLayout {
  background-color: var(--grey-low);
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 21/23;
  width: 100%;
  height: 100%;
  min-height: 220px;

  @include mobile-all {
    aspect-ratio: 10/9;
    min-height: 0;
    border-right: none;
    border-radius: 16px;
  }

  .withGradient::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 33%;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0) 95%
    );
    z-index: 1; // This ensures the gradient is above the image
  }

  .playButtonHolder {
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 2; // This ensures the button is above the gradient

    width: 32px;
    height: 32px;

    @include desktop-wide {
      width: 40px;
      height: 40px;
    }
  }

  .image {
    border-radius: 16px !important;

    @include mobile-all {
      border-radius: 16px !important;
    }
  }
}
