@import "@styles/variables";
@import "@styles/mixins";

.container {
  border: 1px solid var(--colorV2-grey-medium-dark);
  border-radius: 28px;
  background-color: var(--white);
  color: var(--colorV2-black);
  width: 100%;

  @include mobile-all {
    background-color: var(--colorV2-grey-light-medium);
    border: 1px solid var(--colorV2-grey-medium-dark);
  }
}

.iconContainer {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin-top: 2px;
  background-color: var(--colorV2-light-green);
  color: var(--colorV2-black);
  border-radius: 50%;
  box-sizing: border-box;
}

.contactBoxContainer {
  position: sticky;
  z-index: 20;
  top: var(--spacer-size-7);
}

.image {
  width: 100%;
  height: auto;
}
