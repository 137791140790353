@import "@styles/variables";
@import "@styles/mixins";

.dialogOverlay {
  background-color: var(--blackA9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @include mobile-all {
    display: none;
  }
}

.dialogHeader {
  background-color: var(--colorV2-light-green);
}

.dialogContent {
  color: var(--colorV2-black);
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: fit-content;
  width: 590px;
  max-height: 700px;
  background-color: white;
  border-radius: 12px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @include mobile-all {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }
}

.dialogError {
  text-align: center;
  background-color: var(--red-low);
}
