@import "@styles/variables";
@import "@styles/mixins";

.heroIconText {
  line-height: normal;

  @include mobile-all {
    font-size: 12px;
  }
}

.heroIcon {
  color: var(--colorV2-medium-green);
  stroke-width: 1.8;

  @include mobile-all {
    width: 28px;
    height: 28px;
  }
}
