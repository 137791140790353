@import "@styles/mixins";
@import "@styles/variables";

.textColumn {
  width: 40%;
  @include non-desktop {
    width: 100%;
    align-items: center;
  }
}

.desktopImageContainer {
  flex-basis: auto;
  flex-shrink: 1;
}

.responsiveImage {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: contain;
}
