@import "@styles/variables";
@import "@styles/mixins";

.heroCoupon {
  background-color: var(--white);
  border-radius: 20px;
}

.stats {
  padding: 24px 48px;
  max-width: 672px;
  margin: auto;
  background-color: var(--colorV2-light-green);
  border-radius: 120px;

  @include non-desktop {
    justify-content: space-between;
    width: 100%;
  }

  @include mobile-all {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }
}

.statContainer {
  width: 190px;
  text-transform: uppercase;

  @include non-desktop {
    width: auto;
  }
}

.stat {
  color: var(--colorV2-dark-green);
}

.iconContainer {
  width: 100%;
  height: auto;
  object-fit: cover;

  @include non-desktop {
    width: auto;
    height: 120px;
    object-fit: contain;
  }
}

.card {
  border-radius: 20px;
  transition: all 300ms ease;
  min-height: 320px;
  flex: 1;
  border-color: transparent;

  &::before {
    background-color: var(--white);
  }

  &::before,
  &::after {
    border-radius: 20px;
    box-shadow: none;
  }
}

.selectedCard {
  border: 3px solid var(--colorV2-green);
  border-radius: 23px;
}

.membershipCardFirstSection {
  min-height: 75px;

  @include non-desktop {
    min-height: 0;
  }
}

.membershipCardLastSection {
  min-height: 164px;

  @include non-desktop {
    min-height: 0;
  }
}

.backgroundImageCup {
  position: absolute;
  bottom: 0;
  right: -20px;
  z-index: -1;

  @include non-desktop {
    display: none;
  }
}

.backgroundImagePlant {
  position: absolute;
  bottom: 50px;
  left: -110px;
  z-index: -1;

  @include non-desktop {
    display: none;
  }
}

.backgroundImageLamp {
  position: absolute;
  bottom: -70px;
  right: -95px;
  z-index: -1;

  @include non-desktop {
    display: none;
  }
}

.communityImage {
  max-width: 100%;
  height: auto;

  @include mobile-all {
    width: 100%;
  }
}

.carouselContainer {
  width: 100%;
  position: relative;

  &:hover {
    .carouselPreviousButton,
    .carouselNextButton {
      opacity: 1;
      visibility: visible;
    }
  }
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;

  @include mobile-all {
    display: none;
  }
}

.carouselPreviousButton {
  left: 8px;
}

.carouselNextButton {
  right: 8px;
}

.dialogVideoOverlay {
  background-color: var(--blackA9);
  position: fixed;
  z-index: 0;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.dialogVideoContainer {
  max-width: 80vw;
  max-height: 80vh;
  aspect-ratio: 16/9;
  position: fixed;
  margin: auto;
  border-radius: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include mobile-all {
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
  }
}

.dialogVideo {
  div {
    position: absolute !important;
    top: 0;
    left: 0;
  }
}

.dialogCloseButton {
  position: fixed;
  z-index: 1;
  top: 10px;
  right: 10px;
  color: var(--white);
  pointer-events: auto;
  transition: color 300ms ease;

  &:hover {
    cursor: pointer;
    color: var(--colorV2-light-green);
  }
}

.videoButton {
  @include mobile-all {
    display: none;
  }
}
