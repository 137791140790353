@import "@styles/mixins";
@import "@styles/variables";

.carouselContainer {
  width: 100%;
  position: relative;

  &:hover {
    .carouselPreviousButton:not(.isHidden),
    .carouselNextButton:not(.isHidden) {
      opacity: 1;
      visibility: visible;
    }
  }
}

.carouselPictures {
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  gap: 25px;

  @include mobile-all {
    gap: 20px;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, WebKit-based browsers */
  }
}

.carouselPicture {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-decoration: none;
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.carouselPreviousButton {
  left: 8px;
}

.carouselNextButton {
  right: 8px;
}
