@import '@styles/variables';
@import '@styles/mixins';
@import "@styles/placeholders";


.seoContentHolder {
  border-radius: 40px;
  background-color: var(--colorV2-grey-light);
}

.seoContentText {
  @include genericHtmlStyling;
}
