@import "@styles/variables";
@import "@styles/mixins";

.content {
  overflow: hidden;
}

.inner {
  display: flex;
  gap: 25px;

  @include mobile-all {
    gap: 20px;
  }
}

.item {
  min-width: 0;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
}

.arrow {
  padding-left: 1px;
  color: var(--colorV2-black);
  background-color: var(--white);
  border: none;
  box-shadow: 0px 4px 4px 0px var(--blackA8);
  transition: all 300ms ease;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: var(--colorV2-light-green);
  }

  &.isGreenLayout {
    color: var(--white);
    background-color: var(--colorV2-medium-green);
    box-shadow: none;

    &:hover {
      background-color: var(--colorV2-dark-green);
    }
  }

  &.isHidden {
    display: none;
  }
}

.paginationDotContainer {
  overflow: hidden;
  width: 100px;
  margin: auto;

  &.isCentered {
    justify-content: center;
  }

  &.isGreenLayout {
    .paginationDotWrapper {
      margin: 24px 4px;
    }

    .paginationDot {
      box-shadow: none;
    }

    .activePaginationDot {
      background-color: var(--colorV2-dark-green) !important;
    }
  }
}

.paginationDotWrapper {
  gap: 10px;
  margin: 18px 4px;
  transition: transform 0.3s ease-in-out;
}

.paginationDot {
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 10px;
  height: 10px;
  background-color: var(--colorV2-grey-medium-dark) !important;
  opacity: 0.6;
}

.activePaginationDot {
  transition:
    background-color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
  background-color: var(--white) !important;
  opacity: 1;
}
